import React from 'react';
import { Helmet } from 'react-helmet';

import ErrorLayout from 'components/ErrorLayout';

const NotFoundPage = () => {
  return (
    <ErrorLayout code="404">
      <Helmet bodyAttributes={{ class: 'page-error page-error-404' }}>
        <title>404: Not Found</title>
      </Helmet>
      <h2>Page Not Found</h2>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </ErrorLayout>
  );
};

export default NotFoundPage;
